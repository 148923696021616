export const contactList = [
  {
    name: 'Lucas Nakagawa',
    phoneNumber: '(11) 99887-7717',
    whatsAppLink: 'https://wa.me/5511998877717'
  },
  {
    name: 'Mateus Fugita',
    phoneNumber: '(11) 98874-2687',
    whatsAppLink: 'https://wa.me/5511988742687'
  },
  {
    name: 'João Paulo',
    phoneNumber: '(11) 96753-5340',
    whatsAppLink: 'https://wa.me/5511967535340'
  },
]