import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import Institutional from "./pages/Institutional"

const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(
  <React.StrictMode>
    <Institutional></Institutional>
  </React.StrictMode>
)
